<template>
    <Dialog :title="title" :visible.sync="dialogVisible" :width="width" :before-close="handle">
        <slot></slot>
    </Dialog>
</template>

<script>
import { Dialog } from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

export default {
    props: {
        title: {
            type: String,
            required: true
        },
        width: {
            type: String,
            default: '50%'
        },
        isVisible: {
            type: Boolean,
            required: true
        },
        handleClose: {
            type: Function,
            required: true
        }
    },
    data() {
        return {
            dialogVisible: this.isVisible // Initialize with prop value
        };
    },
    components: {
        Dialog
    }, 
    watch: {
        isVisible(newVal) {
            this.dialogVisible = newVal; // Update dialog visibility when prop changes
        },
        dialogVisible(newVal) {
            this.$emit('update:isVisible', newVal); // Emit update event to parent
        }
    },
    methods: {
        handle(done) {
            this.handleClose()
            done();
        }
    }
};
</script>